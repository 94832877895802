import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e1175f5e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a8aad21e = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _090b25f0 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _0cd965ce = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _028cad2d = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _591bf368 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1773821b = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _2cd5bef0 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _25e4886b = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _79fb8c74 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _4cca91e0 = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _0ad49339 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _08b2ec98 = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _445dc7c0 = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _c0126f7c = () => interopDefault(import('../pages/widget/description.vue' /* webpackChunkName: "pages/widget/description" */))
const _2dabb6d8 = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _5ba2a879 = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _b4edb6e0 = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _27f16c23 = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _015deed2 = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _03141c4e = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _e484a1ee = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _e1175f5e,
    name: "index___en"
  }, {
    path: "/ru",
    component: _e1175f5e,
    name: "index___ru"
  }, {
    path: "/en/cart",
    component: _a8aad21e,
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _090b25f0,
    name: "categories___en"
  }, {
    path: "/en/cookies",
    component: _0cd965ce,
    name: "cookies___en"
  }, {
    path: "/en/events",
    component: _028cad2d,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _591bf368,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _1773821b,
    name: "logout___en"
  }, {
    path: "/en/payment",
    component: _2cd5bef0,
    name: "payment___en"
  }, {
    path: "/en/profile",
    component: _25e4886b,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _79fb8c74,
    name: "register___en"
  }, {
    path: "/en/restore",
    component: _4cca91e0,
    name: "restore___en"
  }, {
    path: "/en/search",
    component: _0ad49339,
    name: "search___en"
  }, {
    path: "/en/widget",
    component: _08b2ec98,
    name: "widget___en"
  }, {
    path: "/ru/cart",
    component: _a8aad21e,
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _090b25f0,
    name: "categories___ru"
  }, {
    path: "/ru/cookies",
    component: _0cd965ce,
    name: "cookies___ru"
  }, {
    path: "/ru/events",
    component: _028cad2d,
    name: "events___ru"
  }, {
    path: "/ru/login",
    component: _591bf368,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _1773821b,
    name: "logout___ru"
  }, {
    path: "/ru/payment",
    component: _2cd5bef0,
    name: "payment___ru"
  }, {
    path: "/ru/profile",
    component: _25e4886b,
    name: "profile___ru"
  }, {
    path: "/ru/register",
    component: _79fb8c74,
    name: "register___ru"
  }, {
    path: "/ru/restore",
    component: _4cca91e0,
    name: "restore___ru"
  }, {
    path: "/ru/search",
    component: _0ad49339,
    name: "search___ru"
  }, {
    path: "/ru/widget",
    component: _08b2ec98,
    name: "widget___ru"
  }, {
    path: "/en/profile/tickets",
    component: _445dc7c0,
    name: "profile-tickets___en"
  }, {
    path: "/en/widget/description",
    component: _c0126f7c,
    name: "widget-description___en"
  }, {
    path: "/ru/profile/tickets",
    component: _445dc7c0,
    name: "profile-tickets___ru"
  }, {
    path: "/ru/widget/description",
    component: _c0126f7c,
    name: "widget-description___ru"
  }, {
    path: "/en/categories/:cid",
    component: _2dabb6d8,
    name: "categories-cid___en"
  }, {
    path: "/en/events/:eid",
    component: _5ba2a879,
    name: "events-eid___en"
  }, {
    path: "/en/groups/:id?",
    component: _b4edb6e0,
    name: "groups-id___en"
  }, {
    path: "/en/payment/:hash",
    component: _27f16c23,
    name: "payment-hash___en"
  }, {
    path: "/en/restore/:key",
    component: _015deed2,
    name: "restore-key___en"
  }, {
    path: "/ru/categories/:cid",
    component: _2dabb6d8,
    name: "categories-cid___ru"
  }, {
    path: "/ru/events/:eid",
    component: _5ba2a879,
    name: "events-eid___ru"
  }, {
    path: "/ru/groups/:id?",
    component: _b4edb6e0,
    name: "groups-id___ru"
  }, {
    path: "/ru/payment/:hash",
    component: _27f16c23,
    name: "payment-hash___ru"
  }, {
    path: "/ru/restore/:key",
    component: _015deed2,
    name: "restore-key___ru"
  }, {
    path: "/en/events/:eid/tickets",
    component: _03141c4e,
    name: "events-eid-tickets___en"
  }, {
    path: "/ru/events/:eid/tickets",
    component: _03141c4e,
    name: "events-eid-tickets___ru"
  }, {
    path: "/en/:slug",
    component: _e484a1ee,
    name: "slug___en"
  }, {
    path: "/ru/:slug",
    component: _e484a1ee,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
